import { useEffect, useState } from "react";
import {
    DifferenceBetweenDates,
    IsEligibleForSevenExtraDays,
} from "../../../Helpers/Utility";
import { ISubscriptionDTO } from "../../../Models/DTOs/ISubscriptionDTO";
import CancelOrPause from "../Forms/CancelOrPause";
import ExtraDays from "../Forms/ExtraDays";
import CancelOrPausePopUp from "./CancelOrPausePopUp";
import Heading from "../Text/Heading";
import PrimaryText from "../Text/PrimaryText";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import styled from "styled-components";
import Card from "../Card";

const CancelModalWrapper = styled.div`
    width: 100%;
    max-width: calc(600rem / 16);
    h2,
    p {
        text-align: center;
        margin: 0 0 1rem 0;
    }
`;

interface CancelOrPauseModalProps {
    display: boolean;
    setDisplay: (value: boolean) => void;
    subscription: ISubscriptionDTO;
}

function CancelOrPauseModal({ display, setDisplay, subscription }: CancelOrPauseModalProps) {
    const [extraDays, setExtraDays] = useState<boolean>(true);
    const [displayExtraDays, setDisplayExtraDays] = useState<boolean>(true);

    useEffect(() => {
        const startDate = new Date(subscription.SubscriptionStartDate);
        const endDate = new Date(subscription.SubscriptionEndDate);
        const difference = DifferenceBetweenDates(endDate, startDate);
        const result = IsEligibleForSevenExtraDays(startDate, difference);

        setExtraDays(result);

        if (display) {
            setDisplayExtraDays(result);
        }
    }, [display, subscription]);

    return (
        <CancelOrPausePopUp
            canBeDismissed={true}
            isFullScreen={true}
            openEvent="cancelAutoRenewal"
            openBool={display} // Correct prop to control visibility
        >
            <Card>
                <CancelModalWrapper>
                    <CancelOrPause />
                </CancelModalWrapper>
            </Card>
        </CancelOrPausePopUp>
    );
}

export default CancelOrPauseModal;

