import React, {useState} from 'react';
import {useEffect} from "react";
import {AxiosError} from "axios";
import styled from "styled-components";

// Assets
import {IconCheckMark} from "../../Assets/Icons/Icons";

// Models
import IPlanDTO from "../../Models/DTOs/IPlanDTO";

// Api
import {GetPlans} from "../../Api/Subscription";

interface PlansSelectorProps {
    onSelectPlan: (plan: IPlanDTO) => void;
    parentSelectedPlan?: IPlanDTO | null;
    hasError: boolean;
    errorMessage: string;
    featuresStart: number;
    featuresEnd: number;
}

const PlansSelectorDiv = styled.div`
    margin: 0;
    width: 100%;
    label {
        display: block;
        width: 100%;
        padding: calc(5rem/16);
        color: #da2626;
    }

    .form__plan-single {
        cursor: pointer;
        position: relative;
        padding: calc(24rem/16);
        border-radius: calc(10rem/16);
        box-shadow: inset 0 0 0 calc(2rem / 16) #303030;
        margin: 0 0 2rem 0;
        transition: box-shadow .2s cubic-bezier(.84,0,.13,.98), opacity .2s cubic-bezier(.84,0,.13,.98);
        &:last-of-type {
            margin: 0;
            svg {
                * {
                    fill: #e8bb27;
                }
            }
        }

        .plan-single__label,
        h2,
        h3,
        span {
            line-height: 1.3;
            transition: color .2s cubic-bezier(.84,0,.13,.98);
        }

        svg {
            position: absolute;
            top: calc(10rem/16);
            right: calc(10rem/16);
            height: calc(18rem/16);
            width: calc(18rem/16);
            * {
                fill: #b760b4;
            }
        }

        .plan-single__label {
            position: absolute;
            top: calc(-8rem/16);
            left: calc(50% - (60rem/16));
            width: calc(120rem/16);
            text-align: center;
            text-transform: uppercase;
            margin: 0;
            font-weight: normal;
            padding: calc(2rem/16);
            background: #0e0e0e;
            box-shadow: inset 0 0 0 calc(2rem / 16) #303030;
            font-size: calc(12rem/16);
            border-radius: calc(5rem/16);
            transition: box-shadow .2s cubic-bezier(.84,0,.13,.98);
            span {
                position: absolute;
                bottom: 0;
                left: 0;
                //box-shadow: inset 0 0 0 calc(2rem/16) #e8bb27;
                background: #e8bb27;
                color: black;
                text-transform: uppercase;
                font-weight: bold;
                font-family: "Outfit", Helvetica sans-serif;
                font-size: calc(12rem/16);
                text-align: center;
                padding: calc(2rem/16) 0 0 0;
                border-top-left-radius: calc(5rem/16);
                border-top-right-radius: calc(5rem/16);
                transition: opacity .2s cubic-bezier(.84,0,.13,.98);
                z-index: 1;
            }
        }

        .plan-single__flag {
            top: calc(35rem/16);
            left: unset;
            right: calc(2rem/16);
            overflow: hidden;
            height: calc(20rem/16);
            width: calc(100rem/16);
            padding: 0 0 0 calc(10rem/16);
            span {
                left: unset;
                right: 0;
                width: calc(100% - (10rem/16));
                background: #e8bb27;
                color: black;
                text-transform: uppercase;
                font-weight: bold;
                font-family: "Outfit", Helvetica sans-serif;
                font-size: calc(12rem/16);
                text-align: center;
                padding: calc(2rem/16) 0 0 0;
                transition: opacity .2s cubic-bezier(.84,0,.13,.98);
                z-index: 1;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    left: calc(-8rem/16);
                    height: calc(16rem/16);
                    width: calc(16rem/16);
                    transform: rotate(45deg);
                    background: #e8bb27
                }

                &:before {
                    top: calc(-8rem/16);
                }

                &:after {
                    bottom: calc(-8rem/16);
                }
            }
        }

        h2 {
            font-family: "Rubik", Helvetica sans-serif;
            font-weight: 500;
            font-size: calc(24rem/16);
            margin: calc(8rem/16) 0 calc(12rem/16) 0;
        }

        h3 {
            font-family: "Outfit", Helvetica sans-serif;
            margin: 0 0 calc(16rem/16) 0;
            font-size: calc(24rem/16);
            span {
                font-weight: bold;
                &:first-of-type {
                    color: #b760b4;
                }

                &:last-of-type {
                    display: block;
                    font-weight: normal;
                    font-size: calc(12rem/16);
                    text-transform: uppercase;
                    margin: calc(5rem/16) 0 0 0;
                }
            }
        }

        ul {
            margin: 1.25rem 0 0 0;
            padding: 0 0 0 1.25rem;
        }

        .plan-single__feature {
            font-size: calc(14rem/16);
            margin: 0 0 calc(5rem/16) 0;
            font-family: "Manrope", Helvetica sans-serif;
            &:last-of-type {
                margin: 0;
            }
        }

        &.plan-selected {
            &.is-not-selected {
                opacity: 0.6;
            }
        }
    }

    .error-message {
        text-align: center;
    }

    @media screen and (min-width: 36.5em) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .form__plan-single {
            width: calc(50% - (4rem/16));
            margin: 0;
        }
    }

    &.is-not-loading {
        .form__plan-single {
            &:hover,
            &:focus,
            &:active,
            &.is-selected {
                box-shadow: inset 0 0 0 calc(2rem/16) #b760b4;
                .plan-single__label {
                    box-shadow: inset 0 0 0 calc(2rem/16) #b760b4;
                }

                &:last-of-type {
                    box-shadow: inset 0 0 0 calc(2rem/16) #e8bb27;
                    .plan-single__label {
                        box-shadow: inset 0 0 0 calc(2rem/16) #e8bb27;
                    }
                }
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 1;
                &:last-of-type {
                    .plan-single__label {
                        span {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &.is-loading {
        .form__plan-single {
            &.u-relative_hidden {
                height: calc(240rem/16);
                &.has-dark-mode {
                    background: #303030;
                }
            }
        }
    }
`;

const PlansSelector: React.FC<PlansSelectorProps> = ({ onSelectPlan, parentSelectedPlan, hasError, errorMessage, featuresStart, featuresEnd }) => {
    const

        /**
         * Local state
         */
        [plans, setPlans] = useState<IPlanDTO[]>([]),
        [selectedPlan, setSelectedPlan] = useState<IPlanDTO | null>(null),

        /**
         * Handlers
         */
        handleSelectPlan = (plan: IPlanDTO) => {
            setSelectedPlan(plan);
            onSelectPlan(plan);
        },

        /**
         * Api Requests
         */
        abortController = new AbortController(),
        fetchPlans = async () => {
            try {
                // Await the promise and store the response directly
                const getPlansApiResponse = await GetPlans("GB", abortController);

                if (!(getPlansApiResponse instanceof AxiosError)) {
                    setPlans(getPlansApiResponse);
                }
            } catch (err) {
                throw new Error("fetchPlans failed");
            } finally {}
        };

        useEffect(() => {
            fetchPlans();
        }, []);

    useEffect(() => {
        if (parentSelectedPlan) {
            setSelectedPlan(parentSelectedPlan);
        }
    }, [parentSelectedPlan]);

    return (
        <PlansSelectorDiv className={`ickonic-plan-selector ${plans.length === 0 ? 'is-loading' : 'is-not-loading'}`}>
            {plans && plans.length > 0 ? (
                plans.map((plan, i) => (
                    <div
                        key={i}
                        className={`form__plan-single ${selectedPlan !== null ? 'plan-selected' : 'no-plan-selected'} ${selectedPlan?.PlanId === plan.PlanId ? 'is-selected' : 'is-not-selected'}`}
                        onClick={() => handleSelectPlan(plan)}
                    >
                        {/** isSelected Checkmark **/}
                        {selectedPlan?.PlanId === plan.PlanId && <IconCheckMark />}

                        {/** Plan name / label **/}
                        <p className="plan-single__label u-relative_hidden">
                            {plan.Title}
                        </p>

                        {/** Billing Period **/}
                        <h2>{i === 0 ? "Monthly" : "Annual"}</h2>

                        {/** Price Information **/}
                        <h3>
                            <>
                                <span>£{plan.Phases[0].Price}</span> {i === 0 ? "First Month" : "First Year"}
                                <span>Then £{plan.Price} {i === 0 ? "Billed Monthly" : "Billed Annually"}</span>
                            </>
                        </h3>

                        {/** Plan features **/}
                        {plan.Text && plan.Text.length > 0 && (
                            <ul>
                                {plan.Text.slice(featuresStart, featuresEnd).map((feature, j) => (
                                    <li className="plan-single__feature" key={i + j}>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        )}

                        {i !== 0 && (<div className="plan-single__flag u-full_cover_absolute"><span className="u-full_cover_absolute">Best Value</span></div>)}
                    </div>
                ))
            ) : (
                <>
                    {/** Loading UI **/}
                    <div className="form__plan-single u-relative_hidden">
                        <div className={`u-full_cover_absolute loader--side-to-side`}/>
                    </div>

                    <div className="form__plan-single u-relative_hidden">
                        <div className={`u-full_cover_absolute loader--side-to-side`}/>
                    </div>
                </>
            )}

            {hasError && (<label className="error-message">{errorMessage}</label>)}
        </PlansSelectorDiv>
    );
};

// Dummy Data
const dummyPlans = [
    {
        "Title": "Truth Seeker",
        "Currency": "GBP",
        "Symbol": "&#163;",
        "Price": 7.99,
        "PlanId": "price_1N6ZgCKO1xu37edMK13XUo6t",
        "IsPromotion": false,
        "Text": [
            "Billed Monthly",
            "Stream on 1 device\n",
            "Instant access to over 1500 unique videos",
            "Access 30+ years of David Icke's Content\n",
            "Watch on your phone and TV\n",
            "£1.99 for your first month\n",
            "Renews at £7.99\n"
        ],
        "Period": 0,
        "Type": 7,
        "Phases": [
            {
                "Price": 1.99,
                "Name": "price_1NkSCoKO1xu37edMIOLBp4FA"
            },
            {
                "Price": 7.99,
                "Name": "price_1N6ZgCKO1xu37edMK13XUo6t"
            }
        ]
    },
    {
        "Title": "Game Changer",
        "Currency": "GBP",
        "Symbol": "&#163;",
        "Price": 79,
        "PlanId": "price_1N6ZfkKO1xu37edM1YWT2eqE",
        "IsPromotion": false,
        "Text": [
            "Billed annually",
            "Best value",
            "Watch on up to 2 devices\n",
            "Save 20% Annually vs Truth Seeker Package\n",
            "Stream Exclusive Ickonic Originals\n",
            "Access to Ickonic Live Events\n",
            "Instant access to over 1500 unique videos",
            "Access 30+ years of David Icke's Content\n",
            "Watch on your phone and TV\n",
            "£60 for your first year\n",
            "Renews at £79\n"
        ],
        "Period": 1,
        "Type": 8,
        "Phases": [
            {
                "Price": 60,
                "Name": "price_1NkSDHKO1xu37edM5PkcZ7B2"
            },
            {
                "Price": 79,
                "Name": "price_1N6ZfkKO1xu37edM1YWT2eqE"
            }
        ]
    }
];

export default PlansSelector;
