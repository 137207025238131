import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { Fonts } from "../../../Constants/Fonts";
import { Ease } from "../../../Constants/EasingCurves";

const Button = styled.button`
    box-shadow: inset 0 0 0 calc(2rem/16) ${ Colours.IckonicPink };
    background: none;
    color: ${ Colours.Text };
    font-family: ${ Fonts.Secondary };
    text-decoration: none;
    font-size: 14pt;
    padding: 13px 30px;
    display: inline-flex;
    justify-content: center;
    border-radius: 31px;
    text-align: center;
    border: 0;
    line-height: 1;
    margin: 0 auto;
    font-weight: 600;
    transition: width .25s ${ Ease.Smooth }, left .25s ${ Ease.Smooth }, background .25s ${ Ease.Smooth }, color .25s ${ Ease.Smooth }, box-shadow .25s ${ Ease.Smooth };
    display: inline-flex;
    justify-content: center;
    :disabled {
        box-shadow: inset 0 0 0 calc(2rem/16) ${ Colours.Tertiary };
        color: ${ Colours.SecondaryDarker };
        background: ${ Colours.Tertiary };
        opacity: 0.7;
    }

    &:not(:disabled):hover {
        cursor: pointer;
        background: ${ Colours.IckonicPink };
    }
        
    svg {
        margin: 0 calc(8rem/16) 0 0;
    }
`;

function PinkButton(props: {
    children: React.ReactNode, 
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void,
    disabled?: boolean, 
    type?: 'submit' | 'reset' | 'button' | undefined,
    className?: string,
    testId?: string,
    title?: string // Add title prop
}){
    return(
        <Button className={props.className ?? ""} onClick={props.onClick} disabled={props.disabled} type={props.type} data-testid={props.testId}  title={props.title} >
            {props.children}
        </Button>
    )
}

export default PinkButton;